import React, { createContext, useState, useContext } from 'react';

const PresentationModeContext = createContext();
export const usePresentationMode = () => useContext(PresentationModeContext);

export const PresentationModeProvider = ({ children }) => {
    const [isPresentationMode, setIsPresentationMode] = useState(false);

    const togglePresentationMode = () => {
        setIsPresentationMode((prevMode) => !prevMode);
        document.body.classList.toggle('presentation-mode');
    };

    return (
        <PresentationModeContext.Provider value={{ isPresentationMode, togglePresentationMode }}>
            {children}
        </PresentationModeContext.Provider>
    );
};
