import {Handle, Position} from "@xyflow/react";
import { Tooltip } from "@mui/material";

export const LeftHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="Left"
            type="target"
            position={Position.Left}
            style={{width: "20px", height: "20px"}}
            isValidConnection={isValidConnection}
        />
    )
}

export const TopLeftHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="TopLeft"
            type="target"
            position={Position.Left}
            style={{width: "20px", height: "20px", top: "50%", marginTop: "-25px"}}
            isValidConnection={isValidConnection}
        />
    )
}

export const BottomLeftHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="BottomLeft"
            type="target"
            position={Position.Left}
            style={{width: "20px", height: "20px", top: "50%", marginTop: "25px"}}
            isValidConnection={isValidConnection}
        />
    )
}

export const RightHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="Right"
            type="source"
            position={Position.Right}
            style={{width: "20px", height: "20px"}}
            isValidConnection={isValidConnection}
        />
    )
}

export const TopRightHandle = ({isValidConnection, isMeanBlock}) => {
    return (
        <Tooltip title={isMeanBlock && "Full Table with Mean Columns"} placement="top" enterDelay={0} leaveDelay={300} slotProps={{ tooltip: { sx: { fontSize: '1rem' } } }}>
            <Handle
                id="TopRight"
                type="source"
                position={Position.Right}
                style={{width: "20px", height: "20px", top: "50%", marginTop: "-25px"}}
                isValidConnection={isValidConnection}
            />
        </Tooltip>
    )
}

export const BottomRightHandle = ({isValidConnection, isMeanBlock}) => {
    return (
        <Tooltip title={isMeanBlock && "Mean / Truncated Table"} placement="bottom" enterDelay={0} leaveDelay={300} slotProps={{ tooltip: { sx: { fontSize: '1rem' } } }}>
            <Handle
                id="BottomRight"
                type="source"
                position={Position.Right}
                style={{width: "20px", height: "20px", top: "50%", marginTop: "25px"}}
                isValidConnection={isValidConnection}
            />
        </Tooltip>
        )
}