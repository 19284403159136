import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, TextField } from "@mui/material";
import List from '@mui/material/List';
import { ConfigurePlotElementHeader } from "./PlotComponents/ConfigurePlotSidebarComponent";
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useState, useEffect } from 'react';
import AdminModeOptionsList from "./AdminModeOptionsList";
import Autocomplete from "@mui/material/Autocomplete";

export const MultiSelectConfigListItem = ({ option, onClick, checked, isForeignPipe = false, index, isShowTable, isAdminMode, onVisibilityChange }) => {
    
    return (
        <Draggable draggableId={option} index={index} isDragDisabled={!isShowTable}>
            {(provided) => (
                !isAdminMode &&
                <ListItemButton
                    dense
                    onClick={onClick}
                    disabled={isForeignPipe()}
                    sx={{ ml: -2, wordWrap: 'break-word', width: '120%' }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <ListItemIcon
                        style={{ height: 20, minWidth: 20 }}
                        sx={{ ml: -2 }}
                    >
                        <Checkbox
                            checked={checked}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText
                        style={{ margin: 0 }}
                        primary={<Typography variant='body2'>{option}</Typography>}
                    />
                </ListItemButton>
            )}
        </Draggable>
    )
}

const MultiSelectConfigList = ({
    config,
    onSelectOption,
    isForeignPipe,
    onChangeIsSynced,
    onChangeSortDescending,
    onOptionsReorder,
    isAdminMode,
    onVisibilityChange,
    isShowTableBlock = false
}) => {
    const [options, setOptions] = useState(
        config.options?.filter(option => !config.hidden_options?.includes(option)) || []
    );

    useEffect(() => {
        // Update options when config changes or admin mode changes
        const filteredOptions = isAdminMode
            ? config.options || []
            : config.options?.filter(option => !config.hidden_options?.includes(option)) || [];
        setOptions(filteredOptions);
    }, [config.options, config.hidden_options, isAdminMode]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedOptions = Array.from(options);
        const [removed] = reorderedOptions.splice(result.source.index, 1);
        reorderedOptions.splice(result.destination.index, 0, removed);

        setOptions(reorderedOptions);

        // Now propagate the change to both `options` and `selected_values`
        onOptionsReorder(reorderedOptions, config.selected_values);
    };

    const invalidOptions = config.selected_values.filter((value) => !config.options?.includes(value))

    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <ConfigurePlotElementHeader
                    config={config}
                    onChangeIsSynced={onChangeIsSynced}
                    onChangeSortDescending={onChangeSortDescending}
                />
            </AccordionSummary>
            <AccordionDetails>
                {isAdminMode ? (
                    <AdminModeOptionsList
                        config={config}
                        onVisibilityChange={onVisibilityChange}
                        displayNone={false}
                    />
                ) : isShowTableBlock ? (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <List
                                    dense
                                    sx={{ mt: -3 }}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {options.map((option, index) => (
                                        <MultiSelectConfigListItem
                                            option={option}
                                            onClick={() => { onSelectOption(option) }}
                                            checked={config.selected_values.includes(option)}
                                            isForeignPipe={isForeignPipe}
                                            index={index}
                                            key={option}
                                            isShowTable={isShowTableBlock}
                                            isAdminMode={isAdminMode}
                                            onVisibilityChange={onVisibilityChange}
                                        />
                                    ))}
                                    {provided.placeholder}
                                    {invalidOptions.length > 0 && <>
                                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>Invalid options</Typography>
                                        {invalidOptions.map((option, index) => (
                                            <MultiSelectConfigListItem
                                                option={option}
                                                onClick={() => { onSelectOption(option) }}
                                                checked={config.selected_values.includes(option)}
                                                isForeignPipe={isForeignPipe}
                                                index={options.length + index}
                                                key={option}
                                                isShowTable={isShowTableBlock}
                                                isAdminMode={isAdminMode}
                                                onVisibilityChange={onVisibilityChange}
                                            />
                                        ))}
                                    </>}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
            ) : (
                <>
                    <Autocomplete
                        multiple
                        options={options}
                        value={config.selected_values}
                        onChange={(event, newValue) => {
                            onSelectOption(newValue)}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Select options"
                                fullWidth
                            />
                        )}
                        disabled={isForeignPipe()}
                        sx={{ 
                            width: "100%", 
                            "& .MuiAutocomplete-inputRoot": {
                                flexWrap: "wrap",
                            },
                        }}
                        ChipProps={{
                            sx: {
                              width: "110px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            },
                          }}
                    />
                    {invalidOptions.length > 0 && (<>
                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>Invalid options</Typography>
                            <Autocomplete
                                multiple
                                options={invalidOptions}
                                value={invalidOptions}
                                disabled
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Invalid options"
                                />
                                )}
                            />
                        </>
                    )}
                </>
            )}
            </AccordionDetails>
        </Accordion>
    )
}

export default MultiSelectConfigList;