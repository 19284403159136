import UserPermission from "helper/UserPermission";
import { useUserProfile } from './useAPIs';



const useFeatureFlags = () => {
    const {data: user} = useUserProfile()

    const enableDragToReorder = false
    const hideCountColumn = user !== undefined && user.permissions.some((p) => p.name === UserPermission.HIDE_COUNT_COLUMN)
    const enableDashboardAccordions = user !== undefined && (user.permissions.some((p) => p.name === UserPermission.DASHBOARD_ACCORDIONS) || user.username === "tim.haban@rwa.at")
    const exludeImplicitDeselectedOptionsFromFilter = user !== undefined && user?.organization?.name === "RWA"
    const currentUserIsAdmin = user !== undefined && user.permissions.some((p) => p.name === UserPermission.ADMIN)

    return {
        hideCountColumn: hideCountColumn,
        enableDragToReorder: enableDragToReorder,
        enableDashboardAccordions: enableDashboardAccordions,
        exludeImplicitDeselectedOptionsFromFilter: exludeImplicitDeselectedOptionsFromFilter,
        currentUserIsAdmin: currentUserIsAdmin,
    }
}

export default useFeatureFlags;