import { Delete, Edit, Visibility } from "@mui/icons-material";
import { Box, Dialog, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import DeleteDialogComponent from "components/dialogs/DeleteDialogComponent";
import EditPipeDialogComponent from "components/dialogs/EditPipeDialogComponent";
import { encodePipeId, useURLParts } from "helper/UrlUtils";
import useFeatureFlags from "helper/useFeatureFlags";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logger from "../../helper/Logger";
import Route from "../../helper/Route";
import { UserContext } from "../../helper/UserContext";
import UserPermission from "../../helper/UserPermission";
import DefaultPaper from "../DefaultPaper";
import { PipeItemComponent } from "../PipeItemComponent";
import { ChangeCostCentersComponent } from "./UserManagementPageComponent";
import { useTemplates, usePipes, useCreatePipe, useDeletePipe, useDeleteTemplate, useUpdateTemplateTag, useUpdateTemplateCostCenters, useUpdateTemplateOrganization } from '../../helper/useAPIs'

const PipesRowComponent = ({ pipes, onClick, onEditClick, onDeleteClick, onVisibilityClick }) => {
    const { user } = useContext(UserContext);
    const { currentUserIsAdmin } = useFeatureFlags()

    const tooltipContent = (pipe) => {
        const shouldShowEditOption = currentUserIsAdmin && pipe.is_template && user && pipe.user_id === user.id
        const shouldShowVisibilityOption = currentUserIsAdmin && pipe.is_template && user && pipe.user_id === user.id
        const shouldShowDeleteOption = !pipe.is_template || currentUserIsAdmin

        return (
            <Stack direction="row" spacing={1}>
                {shouldShowEditOption && (
                    <IconButton onClick={() => onEditClick(pipe)}>
                        <Edit style={{ color: 'white' }} />
                    </IconButton>)}
                {shouldShowVisibilityOption && (
                    <IconButton onClick={() => onVisibilityClick(pipe)}>
                        <Visibility style={{ color: 'white' }} />
                    </IconButton>)}
                {shouldShowDeleteOption && (
                    <IconButton onClick={() => onDeleteClick(pipe)}>
                        <Delete style={{ color: 'white' }} />
                    </IconButton>)}
                {(!shouldShowDeleteOption && !shouldShowEditOption && !shouldShowVisibilityOption) && (<Typography variant="body2">Clone {pipe.name}</Typography>)}
            </Stack>
        )
    }

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, mt: 2 }}>
            {pipes.map((pipe, index) => (
                <Box key={index}>
                    <Tooltip title={tooltipContent(pipe)} arrow disableHoverListener={pipe.name === "Create new pipe"}>
                        <Box>
                            <PipeItemComponent pipe={pipe} key={index} onClick={() => onClick(pipe)} />
                        </Box>
                    </Tooltip>
                </Box>
            ))}
        </Box>
    )
}

const PipesPageComponent = () => {
    const { user } = useContext(UserContext)
    const { username } = useURLParts()
    const navigate = useNavigate()
    const [editPipeDialogOpen, setEditPipeDialogOpen] = useState(false)
    const [pipeToEdit, setPipeToEdit] = useState(null)
    const [pipeToVisibility, setPipeToVisibility] = useState(undefined)
    const [deletePipeDialogOpen, setDeletePipeDialogOpen] = useState(false)
    const [pipeToDelete, setPipeToDelete] = useState(null)

    const showTemplatesSection = user?.username === username
    const isAdmin = user?.permissions.some((p) => p.name === UserPermission.ADMIN)

    const { data: templates = [] } = useTemplates()
    const { data: pipes = [] } = usePipes(username)
    const createPipeMutation = useCreatePipe()
    const deletePipeMutation = useDeletePipe()
    const deleteTemplateMutation = useDeleteTemplate()
    const updateTemplateTagMutation = useUpdateTemplateTag()
    const updateTemplateCostCentersMutation = useUpdateTemplateCostCenters()
    const updateTemplateOrganizationMutation = useUpdateTemplateOrganization()

    const handleCreateClick = async (template) => {
        if (template) {
            navigate(Route.Template + "/" + template.id)
        } else {
            try {
                await createPipeMutation.mutateAsync({ 
                    name: "new pipe",
                    templateId: undefined
                })
            } catch (error) {
                Logger.error("Could not create new pipe: " + JSON.stringify(error))
            }
        }
    }

    const handlePipeClick = (pipe) => {
        const pipeUrl = encodePipeId(username, pipe.pipe_id)
        if (isAdmin) {
            navigate(Route.Pipe + "/" + pipeUrl)
        } else {
            navigate(Route.Dashboard + "/" + pipeUrl)
        }
    }

    const onSaveEdit = async (pipe, tag) => {
        if (!pipe.is_template) {
            console.log("Trying to edit non-template pipe");
            return
        }

        try {
            await updateTemplateTagMutation.mutateAsync({ 
                templateId: pipe.id, 
                tag 
            })
        } catch (error) {
            Logger.error("Could not update template: " + JSON.stringify(error))
        }
    }

    const onDeleteConfirmed = async (pipe) => {
        try {
            if (pipe.is_template) {
                await deleteTemplateMutation.mutateAsync(pipe.id)
                Logger.info("Template deleted successfully")
            } else {
                await deletePipeMutation.mutateAsync({
                    username: user.username,
                    pipeId: pipe.pipe_id
                })
                Logger.info("Pipe deleted successfully")
            }
        } catch (error) {
            Logger.error(`Could not delete ${pipe.is_template ? 'template' : 'pipe'}: ${JSON.stringify(error)}`)
        }
    }

    const handleVisibilityClose = async (cost_centers, organization) => {
        setPipeToVisibility(undefined)
        try {
            await updateTemplateCostCentersMutation.mutateAsync({
                templateId: pipeToVisibility?.id,
                costCenters: cost_centers
            })
            await updateTemplateOrganizationMutation.mutateAsync({
                templateId: pipeToVisibility?.id,
                organization
            })
        } catch (error) {
            Logger.error("Could not update template visibility: " + JSON.stringify(error))
        }
    }

    return (
        <Box>
            {showTemplatesSection && (
                <DefaultPaper>
                    <Box>
                        <Typography variant="h4">Templates</Typography>
                        <PipesRowComponent
                            pipes={isAdmin ? [{ name: "Create new pipe" }].concat(templates) : templates} // add empty pipe
                            onClick={(template) => {
                                if (template.name === "Create new pipe") {
                                    handleCreateClick()
                                } else {
                                    handleCreateClick(template)
                                }
                            }}
                            onEditClick={(template) => {
                                setEditPipeDialogOpen(true)
                                setPipeToEdit(template)
                            }}
                            onVisibilityClick={(template) => {
                                setPipeToVisibility(template)
                            }}
                            onDeleteClick={(template) => {
                                setDeletePipeDialogOpen(true)
                                setPipeToDelete(template)
                            }}
                        />
                    </Box>
                </DefaultPaper>
            )}
            <DefaultPaper additionalSx={{ mt: showTemplatesSection ? 4 : 7 }}>
                {/* TODO: Find out why we need this box to fix the layout */}
                <Box>
                    <Typography variant="h4">Recent Pipes</Typography>
                    <PipesRowComponent
                        pipes={pipes}
                        onClick={(pipe) => {
                            handlePipeClick(pipe)
                        }}
                        onDeleteClick={(pipe) => {
                            setDeletePipeDialogOpen(true)
                            setPipeToDelete(pipe)
                        }}
                    />
                </Box>
            </DefaultPaper>
            <EditPipeDialogComponent
                open={editPipeDialogOpen}
                pipe={pipeToEdit}
                onClose={() => setEditPipeDialogOpen(false)}
                onSaveEdit={(tag) => {
                    onSaveEdit(pipeToEdit, tag)
                }}
            />
            <Dialog
                open={pipeToVisibility !== undefined}
                onClose={() => setPipeToVisibility(undefined)}
            >
                <ChangeCostCentersComponent
                    target={pipeToVisibility?.name}
                    originalCostCentersValue={pipeToVisibility?.cost_centers}
                    originalOrganizationId={pipeToVisibility?.organization_id}
                    onClose={handleVisibilityClose}
                />
            </Dialog>
            <DeleteDialogComponent
                open={deletePipeDialogOpen}
                objectName={pipeToDelete?.name}
                onClose={() => setDeletePipeDialogOpen(false)}
                onDeleteConfirmed={() => {
                    onDeleteConfirmed(pipeToDelete)
                }}
            />
        </Box>
    )
}

export default PipesPageComponent