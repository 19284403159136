import { AccountCircle } from "@mui/icons-material";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import DefaultPaper from "components/DefaultPaper";
import Route from "helper/Route";
import PipesWidgetComponent from "./PipesWidgetComponent";
import { useUserProfile } from "helper/useAPIs";

const LandingPageDefaultPaper = ({ children, additionalSx }) => {
    return (
        <DefaultPaper
            additionalSx={{ mt: 0, margin: "20px", flexDirection: "column", ...additionalSx }}
        >
            {children}
        </DefaultPaper>
    )
}

const AboutComponent = () => {
    const aboutText = window.REACT_APP_ENV.includes("kws") ?
        "SmartPlot allows breeders and data scientists to visualize data of standard procedures on demand with minimal effort.  Due to the flexible architecture of the software, it is able to cover a wide range of standard processes. Easy to use, it provides the user with top-notch visualizations which are customizable for fast and reliable reporting. Stop working on finding the correct analysis of your data and get more time for the interpretation of the results!" :
        "NoMaze allows breeders and data scientists to visualize data of standard procedures on demand with minimal effort.  Due to the flexible architecture of the software, it is able to cover a wide range of standard processes. Easy to use, it provides the user with top-notch visualizations which are customizable for fast and reliable reporting. Stop working on finding the correct analysis of your data and get more time for the interpretation of the results!"

    return (
        <Grid item xs={12} md={12} lg={12}>
            <LandingPageDefaultPaper>
                <Typography variant="h5">About</Typography>
                <Typography variant="body1">
                    {aboutText}
                </Typography>
            </LandingPageDefaultPaper>
        </Grid>
    )
}

export const ContactComponent = () => {
    const contacts = window.REACT_APP_ENV.includes("kws") ? [
        { "name": "Dirk Jollet", "email": "dirk.jollet@kws.com" },
        { "name": "Torben Schulz-Streek", "email": "torben.schulz-streeck@kws.com" }
    ] : [
        { "name": "Finn Gaida", "email": "finn.gaida@nomaze.com", "imageURL": "https://assets-global.website-files.com/625c7306f5af3d37a364aea7/625dc5442fd0305da4d7bacb_finn.png" },
        { "name": "Jean-Pascal Lutze", "email": "jean-pascal.lutze@nomaze.com", "imageURL": "https://assets-global.website-files.com/625c7306f5af3d37a364aea7/625dc4942c982c5c3445a2bb_jp.png" }
    ]

    return (
        <Grid item xs={12} md={12} lg={12}>
            <LandingPageDefaultPaper additionalSx={{ mt: 2 }}>
                <Typography variant="h5">Contact</Typography>
                <Grid container spacing={2}>
                    {contacts.map((contact, index) => (
                        <Grid item key={index}>
                            {contact.imageURL === undefined ? (
                                <Box sx={{ padding: 3 }}>
                                    <AccountCircle fontSize="large" />
                                </Box>
                            ) : (<img
                                src={contact.imageURL}
                                style={{ width: 100, height: 100 }}
                            />)}
                            <Typography variant="body1"><strong>{contact.name}</strong></Typography>
                            <Link href={"mailto:" + contact.email}>{contact.email}</Link>
                        </Grid>
                    ))}
                </Grid>
            </LandingPageDefaultPaper>
        </Grid>
    )
}

const ExternalLoginComponent = () => {
    return (
        <Grid item xs={12} md={12} lg={12}>
            <LandingPageDefaultPaper>
                <Typography variant="h5">Login</Typography>
                <Typography variant="body1">
                    We use an external auth provider to log you in. Please click the button below to proceed.
                </Typography>
                <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            window.location.replace(window.REACT_APP_API_ENDPOINT + Route.LoginRedirect)
                        }}
                    >
                        Go to Login
                    </Button>
                </Box>
            </LandingPageDefaultPaper>
        </Grid>
    )
}

const LandingPageComponent = () => {
    const { data: user } = useUserProfile();

    return (
        <Grid container spacing={2} sx={{ mt: 5 }}>
            <AboutComponent />
            <Grid item xs={12} md={12} lg={12}>
                <Grid container >
                    <Grid item xs={12} md={4}>
                        <LandingPageDefaultPaper additionalSx={{ height: "100%" }}>
                            <Typography variant="h5">Tutorial</Typography>
                            {window.REACT_APP_ENV.includes("kws") ? (
                                <Box sx={{ height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography variant="h6" sx={{ textAlign: "center" }}>Coming soon!</Typography>
                                </Box>
                            ) : (
                                <iframe
                                    width="100%"
                                    height="80%"
                                    src="https://www.youtube.com/embed/0avQe8-0h9k"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    loading="lazy"
                                ></iframe>
                            )}
                        </LandingPageDefaultPaper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <LandingPageDefaultPaper additionalSx={{ height: "100%" }} >
                            <Typography variant="h5">Latest Release Notes</Typography>
                            <iframe
                                title="Latest Release Notes"
                                src="https://www.nomaze.com/latest-release-notes"
                                width="100%"
                                height="90%"
                                loading="eager"
                            ></iframe>
                        </LandingPageDefaultPaper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {user ? <PipesWidgetComponent /> : <ExternalLoginComponent />}
                    </Grid>
                </Grid>
            </Grid>

            <ContactComponent />
        </Grid>
    );
}

export default LandingPageComponent
