import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

function DropzoneComponent({ setFiles }) {

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            'text/csv': ['.csv'],
            'application/json': ['.json'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        onDrop: setFiles
    });

    const style = useMemo(() => ({
        ...{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#2196f3',
            borderStyle: 'dashed',
            backgroundColor: '#DDEEFF',
            color: '#666666',
            transition: 'border .3s ease-in-out'
        },
        ...(isDragActive ? { borderColor: '#2196f3' } : {}),
        ...(isDragAccept ? { borderColor: '#00e676' } : {}),
        ...(isDragReject ? { borderColor: '#ff1744' } : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <Box>
            <Box {...getRootProps({ className: 'dropzone', style })}>
                <input {...getInputProps()} />
                <p>Drag and drop some files here, or click to select files</p>
                <p>only accepts .csv, .xlsx and .json</p>
            </Box>
            {acceptedFiles.length > 0 &&
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h5">Selected Files</Typography>
                    {acceptedFiles.map((file, index) => (
                        <div key={index}>
                            {file.path} - {(file.size / (1024 * 1024)).toFixed(2)} MB
                        </div>
                    ))}
                </Box>
            }
        </Box>
    );
}

export default DropzoneComponent;
