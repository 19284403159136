import React, { useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import Route from "../../helper/Route";
import { useNavigate } from "react-router-dom";
import Logger from "../../helper/Logger";
import DefaultPaper from "../DefaultPaper";
import { useLogin } from "../../helper/useAPIs";

const LoginPageComponent = ({ isWidget }) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const styling = isWidget === true 
        ? { flexDirection: "column", padding: "20px", margin: "20px", height: "100%" }
        : { padding: 5, width: 320, ml: "auto", mr: "auto" };

    const loginMutation = useLogin();

    const onLoginButtonClick = async () => {
        if (username === "" || password === "") {
            setErrorMessage("You have to fill in the values!")
            return;
        }

        try {
            // Login invalidates pipes and userProfile queries, so no need to force reload the page
            await loginMutation.mutateAsync({
                user: username,
                password: password
            });

            Logger.info("Login success");
            setErrorMessage("");
            navigate(Route.Home);
        } catch (err) {
            Logger.error("Login error: " + JSON.stringify(err));
            if (err?.response?.data?.type) {
                setErrorMessage(err.response.data.type);
            } else if (err?.message) {
                setErrorMessage(err.message);
            } else {
                setErrorMessage("An error occurred during login");
            }
        }
    };

    return (
        <DefaultPaper additionalSx={styling}>
            <Stack direction="column" spacing={2}>
                <h2>Login</h2>
                <TextField 
                    label="username" 
                    value={username} 
                    onChange={(e) => { setUsername(e.target.value) }}
                />
                <TextField 
                    label="password" 
                    type="password" 
                    value={password} 
                    onChange={(e) => { setPassword(e.target.value) }}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            onLoginButtonClick();
                        }
                    }}
                />
                <Button 
                    variant="contained" 
                    onClick={onLoginButtonClick}
                    disabled={loginMutation.isPending}
                >
                    {loginMutation.isPending ? 'Logging in...' : 'Login'}
                </Button>
                {errorMessage && (
                    <div className="alert alert-light" role="alert">
                        {errorMessage}
                    </div>
                )}
            </Stack>
        </DefaultPaper>
    );
};

export default LoginPageComponent;