import { Box, Stack, Typography, Button, Tooltip } from "@mui/material"
import BlockPageComponent from "./BlockPageComponent"
import { useEffect, useMemo, useState } from "react"
import { SwapCalls } from "@mui/icons-material"

const PlotSwapPageComponent = ({ 
    pipeId, 
    nodeId, 
    usernameFromPath, 
    isDashboardItem,
    reload,
    showStaticPlot,
    swapCounter, 
    setSwapCounter, 
    onSelectionChanged,
    showTable = true, 
    parentBlocks = [] 
}) => {

    let components = useMemo(() => { 
        return parentBlocks.map((block) => {
            return <BlockPageComponent
                key={`${block.flow_id}-${showStaticPlot}`}
                pipeId={pipeId}
                nodeId={block.flow_id}
                usernameFromPath={usernameFromPath}
                reload={reload}
                overrideShowStaticPlot={showStaticPlot}
                showTable={showTable}
                isDashboardItem={isDashboardItem}
                hideBlockDetails={true} // always true so we don't get the block details twice
                onSelectionChanged={onSelectionChanged}
            />
        })
    }, [parentBlocks, pipeId, usernameFromPath, reload, showStaticPlot, showTable, isDashboardItem])
    
    return (
        <Stack direction="column">
            <Box>
                <Tooltip title="Switch to alternative block">
                    <Button variant="contained" startIcon={<SwapCalls />} onClick={() => {
                        setSwapCounter(swapCounter + 1)
                    }}>Swap</Button>
                </Tooltip> 
            </Box>
            {components.map((component, index) => (
                <Box key={index} sx={{ display: (index === swapCounter % components.length ? 'block' : 'none') }}>
                    {component}
                </Box>
            ))}
        </Stack>
    )
}

export default PlotSwapPageComponent