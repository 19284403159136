import { Stack, Typography, Box } from "@mui/material";
import { useIsForeignPipe } from "helper/UrlUtils";
import { useContext, useEffect, useState, useRef } from "react";
import { Vega } from "react-vega";
import { compile } from "vega-lite";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import useWindowDimensions from "../../helper/WindowDimensions";
import LoadingSnackbar from "../snackbars/LoadingSnackbar";
import { PlotDescriptionNewlineSeparator } from "helper/Constants";
import { usePresentationMode } from 'helper/PresentationModeContext';

export const SpecLoadingPlotComponent = (
    {
        pipeId,
        nodeId,
        usernameFromPath,
        reload,
        isDashboardItem,
        onViewLoaded = (view) => { },
        patchVegaSpec = (spec) => { },
        onVegaEmbedClick = () => {
            alert("This functionality is still under development. Please let us know if you would like to have it in the near future.")
        },
        setPlotDescriptions = () => { }
    }
) => {
    const { api } = useContext(ApiContext);
    const { height, width } = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(false);
    const [spec, setSpec] = useState({});
    const [reloadSpecCounter, reloadSpec] = useState(0);
    const [canvasWidth, setCanvasWidth] = useState(0); 
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const { isPresentationMode } = usePresentationMode();

    useEffect(() => {
        if (isNaN(pipeId) || isNaN(nodeId)) {
            Logger.error("Pipe ID or Node ID are unexpectedly Nan")
            return
        }
        if (isLoading) {
            return
        }
        const controller = new AbortController()
        const signal = controller.signal
        setIsLoading(true)

        api.getSpec({
            pipe_id: pipeId,
            block_id: nodeId,
            username: usernameFromPath,
            height: height * 0.75 - 200,
            width: (isDashboardItem) ? width * (5/6): width * (3.32/5),
            signal: signal,
            presentationMode: isPresentationMode
        }).then((response) => {
            setIsLoading(false)
            let { spec, description} = response.data;
            updateSpec(spec)
            const descriptions = description ? description.split(PlotDescriptionNewlineSeparator) : [];
            setPlotDescriptions(descriptions)
        }).catch((error) => {
            setIsLoading(false)
            Logger.error("SpecLoadingPlotComponent: Error Raised: " + JSON.stringify(error.name))
        })
        return () => {
            controller.abort()
        }
    }, [reloadSpecCounter, reload, isPresentationMode]);

    const onNewView = (view) => {
        // the view is empty initially, so only set the listener once we have a valid spec
        if (view && view.getState().signals.background !== null) {
            patchVegaExportMenu()
            onViewLoaded(view)
            const canvas = view.container();
            if (canvas) {
                setCanvasWidth(canvas.scrollWidth); 
            }
        }    
    }

    /**
    * This is a bit hacky way to modify the default Vega export menu.
    * Refer to https://github.com/vega/vega-embed/issues/156#issuecomment-583175096
    */
    const patchVegaExportMenu = () => {
        const embedOption = document.createElement('a')
        embedOption.textContent = "Embed"
        embedOption.onclick = () => {
            onVegaEmbedClick()
            document.querySelector('details').removeAttribute('open');
        }

        const sel = document.querySelector('.vega-actions')
        if (sel) {
            // sel.appendChild(embedOption)
        }
    }

/**
    * We need to "hotfix" the incoming vega-lite specs before rendering them. Here we do multiple things:
    * 1. Compile vega-lite spec to vega spec (to get access to raw signals)
    * 2. Send to parents for manual patching
    * @param spec: Incoming (from `hinten`) vega-lite spec
    */
    const updateSpec = (spec) => {
        // console.log(spec)
        let vgSpec = spec
        if (spec["$schema"].includes("vega-lite")) {
            vgSpec = compile(spec).spec
        }
        patchVegaSpec(vgSpec)
        if (isForeignPipe()) {
            vgSpec = {
                ...vgSpec, config: {
                    events: { view: false }, // Disable brush selection
                }
            }
        }
        setSpec(vgSpec);
    }

    return (
        <Stack direction="column">
            <LoadingSnackbar loading={isLoading} />
            <div id={`vega-container`}>
                <Vega
                    spec={spec}
                    renderer="canvas"
                    onNewView={onNewView}
                    actions={false}
                />
            </div>
        </Stack>
    )
}
