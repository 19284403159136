const UserPermission = {
    ADMIN: "admin",
    MANAGE_USERS: "manage_users",
    DELETE_EDGE: "delete_edge",
    DELETE_NODE: "delete_node",
    CREATE_PIPE: "create_pipeline",
    UPLOAD_FILE: "upload_file",
    DASHBOARD_ACCORDIONS: "dashboard_accordions",
    HIDE_COUNT_COLUMN: "hide_count_column"
}

export default UserPermission