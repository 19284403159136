import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"

const ChangeBranchOrderDialog = ({ 
    open, 
    onClose,
    branchOrder, 
    branchNameFromId, 
    selectedBranchNumber, 
    handleBranchOrderChange, 
    handleSubmitOrderChanges 
}) => {

    const handleDragEnd = (result) => {
        if (!result.destination) return
        
        const branchNumbers = Object.keys(branchOrder).filter(branch => branch !== '1')
        
        const reorderedBranches = Array.from(branchNumbers)
        const [movedBranch] = reorderedBranches.splice(result.source.index, 1)
        reorderedBranches.splice(result.destination.index, 0, movedBranch)
   
        const newBranchOrder = {
            1: 1,  
        }
    
        reorderedBranches.forEach((branch, index) => {
            newBranchOrder[branch] = index + 2  
        })
    
        handleBranchOrderChange(newBranchOrder)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="change-order-dialog"
        >
            <DialogTitle>
                Change Branch Order
                <br />
                <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>Current Branch: {selectedBranchNumber}</Typography>
            </DialogTitle>
            <DialogContent>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="branchOrder">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {Object.entries(branchOrder)
                                    .filter(([branchNumber, order]) => branchNumber !== '1')
                                    .sort((left, right) => left[1] - right[1])
                                    .map(([branchNumber, branchOrderItem], index) => (
                                        <Draggable
                                            key={branchNumber}
                                            draggableId={branchNumber}
                                            index={index}
                                            isDragDisabled={parseInt(branchNumber) !== selectedBranchNumber}  
                                        >
                                            {(provided, snapshot) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{
                                                        padding: 2,
                                                        margin: 1,
                                                        border: '1px solid gray',
                                                        borderRadius: '4px',
                                                        backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white', 
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        opacity: branchNumber == selectedBranchNumber || snapshot.isDragging ? 1 : 0.6, // Reduce opacity for non-draggable branches
                                                        cursor: branchNumber == selectedBranchNumber ? 'grab' : 'default'
                                                    }}
                                                >
                                                    <Typography variant="body1">
                                                        {branchOrderItem}: {branchNameFromId(branchNumber)}
                                                    </Typography>
                                                    {branchNumber == selectedBranchNumber && (
                                                        <Typography variant="body2" color="text.secondary">
                                                            (Current)
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Cancel</Button>
                <Button onClick={handleSubmitOrderChanges} variant="contained" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangeBranchOrderDialog
