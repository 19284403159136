import { Box, Dialog } from "@mui/material";
import { Stack } from "@mui/system";
import DTypeMappingDrawerComponent from "components/PlotComponents/DTypeMappingDrawerComponent";
import ListFilesComponent from "components/table/ListFilesComponent";
import Logger from "helper/Logger";
import { useIsForeignPipe } from "helper/UrlUtils";
import usePrevious from "helper/usePrevious";
import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../helper/ApiContext";
import DefaultPaper from "../DefaultPaper";
import UploadDropzoneComponent from "../UploadDropzoneComponent";
import DataLoadingTableComponent from "../table/DataLoadingTableComponent";
import { ChangeCostCentersComponent } from "./UserManagementPageComponent";

const ListFilesPageComponent = ({ pipeId, nodeId, usernameFromPath, showTable = true, onSelectionChanged = () => { } }) => {

    const { api } = useContext(ApiContext);
    const [block, setBlock] = useState(undefined)
    const [reloadFilesCounter, reloadFiles] = useState(0);
    const [files, setFiles] = useState([]);
    const [selectedFilename, setSelectedFilename] = useState(undefined);
    const previousSelectedFilename = usePrevious(selectedFilename)
    const [currentConfigureDTypeFilename, setCurrentConfitureDTypeFilename] = useState(undefined);
    const [currentConfigureVisibilityFilename, setCurrentConfitureVisibilityFilename] = useState(undefined);
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const [selectFileOnceReady, setSelectFileOnceReady] = useState(undefined);

    // pre-select file in list if it is already selected in the block
    useEffect(() => {
        api.getUserFiles(usernameFromPath)
            .then((response) => {
                setFiles(response.data)

                // NOTE: this call needs to happen after the files are loaded
                reloadBlock()
            })
            .catch((error) => {
                console.log(error)
            })
    }, [reloadFilesCounter])

    useEffect(() => {
        if (block && Object.keys(block.data.value).length > 0 && files.length > 0) {
            let selectedFileUUID = block.data.value[0].uuid
            let availableFiles = files.map((f) => (f["uuid"]))
            let selectedFileIndex = availableFiles.indexOf(selectedFileUUID)
            if (selectedFileIndex !== -1) {
                let selectedFileDict = files[selectedFileIndex]
                if (selectedFileDict.filename !== selectedFilename) {
                    setSelectedFilename(selectedFileDict.filename)
                }
            }
        }
    }, [block])

    useEffect(() => {
        // if there is a file flagged to be selected, select it
        if (selectFileOnceReady) {
            let selectedFileIndex = files.findIndex((f) => f.filename === selectFileOnceReady)
            if (selectedFileIndex !== -1) {
                if (selectFileOnceReady === previousSelectedFilename) { return }
                updateSelectedFilename(selectFileOnceReady)
                setSelectFileOnceReady(undefined)
            }
        }
    }, [selectFileOnceReady, files])

    const reloadBlock = () => {
        api.getBlock(pipeId, nodeId, usernameFromPath).then((response) => {
            setBlock(response.data)
        }).catch((error) => {
            Logger.error(`ListFilesPageComponent: Could not get block ${nodeId} in pipe ${pipeId}`, error)
        })
    }

    const fileForFilename = (filename) => {
        let selectedFileIndex = files.findIndex((f) => f.filename === filename)
        if (selectedFileIndex === -1) {
            return undefined
        } else {
            return files[selectedFileIndex]
        }
    }

    const updateSelectedFilename = (selectedFilename) => {
        if (isForeignPipe()) {
            return
        }
        let file = fileForFilename(selectedFilename)
        if (file === undefined) {
            return
        }
        let newValue = {
            [0]: {
                "uuid": file.uuid,
                "filename": selectedFilename,
            }
        }
        api.updateNodeInPipe(pipeId, nodeId, usernameFromPath, newValue).then((response) => {
            Logger.info("Successfully changed node selection in pipe")
            // we need to reload the block to update the configuration
            reloadBlock()
            reloadFiles(reloadFilesCounter + 1)
            onSelectionChanged()
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
    }

    const deleteFileClicked = (filename) => {
        if (filename) {
            api.deleteFile(filename)
                .then((response) => {
                    reloadFiles(reloadFilesCounter + 1)
                }).catch((error) => {
                    Logger.error(`Could not delete file ${filename}`, error)
                })
        }
    }

    const onUploadComplete = (file) => {
        setSelectFileOnceReady(file.name)
        reloadFiles(reloadFilesCounter + 1)
    }

    const filesForTable = () => (files.map((row) => ({
        "File name": row['filename'],
        "Created at": row['creation_date'],
        "Cost centers": row['cost_centers']
    })))

    return (
        <Stack direction={"column"} spacing={3}>
            <Stack direction={"row"} >
                <Box sx={{ width: "80%", mt: 0 }}>
                    <ListFilesComponent
                        files={filesForTable()}
                        selectedFileIndex={files.findIndex((f) => f.filename === selectedFilename)}
                        setSelectedFilename={(filename) => {
                            if (filename !== selectedFilename) {
                                updateSelectedFilename(filename)
                            }
                        }}
                        configureFileClicked={(filename) => setCurrentConfitureDTypeFilename(filename)}
                        toggleVisibilityClicked={(filename) => setCurrentConfitureVisibilityFilename(filename)}
                        deleteFileClicked={deleteFileClicked}
                        isForeignPipe={isForeignPipe}
                    />
                </Box>
                {!isForeignPipe() && <DefaultPaper additionalSx={{ ml: 3, minWidth: 300, width: "20%", mt: 0 }}>
                    <UploadDropzoneComponent
                        onUploadComplete={onUploadComplete}
                    />
                </DefaultPaper>}
            </Stack>
            {showTable && (
                <DataLoadingTableComponent
                    reloadPipeCounter={reloadFilesCounter}
                    pipeId={pipeId}
                    nodeId={nodeId}
                    username={usernameFromPath}
                />
            )}
            <DTypeMappingDrawerComponent
                open={currentConfigureDTypeFilename !== undefined}
                onClose={() => setCurrentConfitureDTypeFilename(undefined)}
                filename={currentConfigureDTypeFilename}
                isForeignPipe={isForeignPipe}
            />
            <Dialog
                open={currentConfigureVisibilityFilename !== undefined}
                onClose={() => setCurrentConfitureVisibilityFilename(undefined)}
            >
                <ChangeCostCentersComponent
                    target={currentConfigureVisibilityFilename}
                    originalCostCentersValue={fileForFilename(currentConfigureVisibilityFilename)?.cost_centers}
                    onClose={(cost_centers, organization) => {
                        setCurrentConfitureVisibilityFilename(undefined)
                        api.updateFileCostCenters(currentConfigureVisibilityFilename, cost_centers)
                            .then((response) => {
                                api.updateFileOrganization(currentConfigureVisibilityFilename, organization)
                                    .then((response) => {
                                        reloadFiles(reloadFilesCounter + 1)
                                    })
                            })
                    }}
                />
            </Dialog>
        </Stack>
    )
}

export default ListFilesPageComponent;
