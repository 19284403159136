import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import NotFoundComponent from 'components/NotFoundComponent';
import BlockPageComponent from 'components/PageComponents/BlockPageComponent';
import UserManagementPageComponent from 'components/PageComponents/UserManagementPageComponent';
import LandingPageComponent from 'components/landingPage/LandingPageComponent';
import { useURLParts } from 'helper/UrlUtils';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { ApiContext } from '../../helper/ApiContext';
import Route from "../../helper/Route";
import PipesPageComponent from "../PageComponents/PipesPageComponent";
import PacmanPlotComponent from "../PlotComponents/PacmanPlotComponent";
import FlowPageComponent from "../flow/FlowPageComponent";
import DashboardComponent from './DashboardComponent';
import ToolbarComponent from "./ToolbarComponent";



const ToolbarPageComponent = () => {
    const { api } = useContext(ApiContext);
    const location = useLocation();
    const { pipeId, nodeId, username, isDashboard, isTemplate } = useURLParts()
    const navigate = useNavigate();

    // useEffect is used since useNavigate is not usable inside the api
    useEffect(() => {
        if (api.currentUserUndefined) {
            api.setCurrentUserUndefined(false)
            navigate(Route.Home)
        }
    }, [api.currentUserUndefined])

    const childComponentForPath = (path) => {
        if (path === undefined) {
            return
        } else if (!isNaN(pipeId)) {
            if (!isNaN(nodeId)) {
                return (<BlockPageComponent pipeId={pipeId} nodeId={nodeId} usernameFromPath={username} />)
            } else if (isTemplate || isNaN(nodeId) && isDashboard !== undefined && !isDashboard) {
                return (<FlowPageComponent />)
            } else if (isDashboard !== undefined && isDashboard) {
                return (<DashboardComponent pipeId={pipeId} usernameFromPath={username} />)
            }
        } else if (username !== undefined && path.includes(Route.Pipes)) {
            return (<PipesPageComponent />)
        }

        switch (path) {
            case Route.Home:
                return (<LandingPageComponent />)
            case Route.UserManagement:
                return (<UserManagementPageComponent />)
            case Route.Pacman:
                return (<PacmanPlotComponent />)
            default:
                return (<NotFoundComponent />)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <ToolbarComponent />
            <Box sx={{
                padding: 4,
                maxWidth: "100vw"
            }}>
                {childComponentForPath(location.pathname)}
            </Box>
        </Box>
    );
}

export default ToolbarPageComponent;