import {FileDownload, FilterList, PlayCircleOutline, SettingsSuggest, Storage} from "@mui/icons-material";
import { PlotBlocks } from "helper/Constants";

export const BlockIcon = ({type}) => {
    if ([...PlotBlocks, 'filter_orthogonal'].includes(type)){
        return <SettingsSuggest/>
    }


    switch (type) {
        case 'import':
            return <Storage/>
        case 'filter_categories':
            return <FilterList/>
        case 'create_subgroup':
            return <FilterList/>
        case 'export':
            return <FileDownload/>
        default:
            return <PlayCircleOutline/>
    }
}