import { IconButton } from '@mui/material';
import {Cancel} from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import {UserContext} from "../../../helper/UserContext";
import UserPermission from '../../../helper/UserPermission';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from '@xyflow/react';

const CustomFlowEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style,
    onCloseButtonClick
}) => {
    const {user} = useContext(UserContext)
    const [shouldShowCloseButton, setShouldShowCloseButton] = useState(false)
    
    useEffect(() => {
        setShouldShowCloseButton(user?.permissions.some((p) => p.name === UserPermission.DELETE_EDGE))
    }, [user])

    const [path, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    
    return (<>
        <BaseEdge id={id} path={path} style={style} className="react-flow__edge-path"/>
        {shouldShowCloseButton && 
            <EdgeLabelRenderer>
                <IconButton 
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        pointerEvents: 'all',
                    }} 
                    color="inherit" 
                    onClick={(event) => {
                        event.stopPropagation()
                        onCloseButtonClick(id)
                    }}>
                    <Cancel/>
                </IconButton>
            </EdgeLabelRenderer>
        }
    </>)
}
    
    export default CustomFlowEdge
    