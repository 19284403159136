import { FilterList, RestartAlt } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import CreateSubgroupNameComponent from "components/filter/CreateSubgroupNameComponent";
import FilterMinMaxComponent from "components/filter/FilterMinMaxComponent";
import DataLoadingTableComponent from "components/table/DataLoadingTableComponent";
import { useIsForeignPipe } from "helper/UrlUtils";
import useFeatureFlags from "helper/useFeatureFlags";
import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import usePrevious from "../../helper/usePrevious";
import { FilterListComponent } from "../filter/FilterListComponent";

const FilterPageComponent = ({ pipeId, nodeId, blockType, usernameFromPath, reload, showTable = true, onSelectionChanged = () => { } }) => {
    const { api } = useContext(ApiContext)
    const [block, setBlock] = useState({})
    const [loading, setLoading] = useState(false)
    const [columns, setColumns] = useState({})
    const [userInput, setUserInput] = useState({})
    const [disabledRows, setDisabledRows] = useState({})
    const previousUserInput = usePrevious(userInput)
    const [reloadPipeCounter, reloadPipe] = useState(0)
    const [reloadTableCounter, reloadTable] = useState(0)
    const [subgroupTitle, setSubgroupTitle] = useState("")
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const { hideCountColumn } = useFeatureFlags();
    const [valueChanged, setValueChanged] = useState(false)

    useEffect(() => {
        setLoading(true)
        reloadTable(reloadTableCounter + 1)

        api.getFilterConfig(pipeId, nodeId, usernameFromPath).then((response) => {
            let { filter_config, user_input, disabled_rows } = response.data

            if (hideCountColumn) {
                Object.keys(filter_config.categorical_deps).forEach((key) => {
                    filter_config.categorical_deps[key].values = filter_config.categorical_deps[key].values.map((v) => {
                        // if v contains count, remove it
                        if (v.hasOwnProperty("count")) {
                            delete v.count
                        }
                        return v
                    })
                })
            }

            let filterConfig = Object.assign(filter_config.numeric_deps, filter_config.categorical_deps, filter_config.ordinal_deps)
            ensureUserInputComplete(user_input, filterConfig)
            setColumns(filterConfig)
            setDisabledRows(disabled_rows)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            Logger.error("Could not get filter config for block " + nodeId + ": " + JSON.stringify(error))
        })

        api.getBlock(pipeId, nodeId, usernameFromPath).then((response) => {
            setBlock(response.data)
        }).catch((error) => {
            Logger.error("FilterPageComponent: could not get block: " + JSON.stringify(error))
        })
    }, [reloadPipeCounter, reload])

    useEffect(() => {
        // first make sure that we don't send an update if the user input is empty or didn't change
        if (userInput && Object.keys(userInput).length > 0 && columns && Object.keys(columns).length === Object.keys(userInput).filter((key) => key !== "subgroup_title").length) {
            if (previousUserInput && JSON.stringify(previousUserInput) === JSON.stringify(userInput)) {
                return
            } else {
                // to reduce the size of the filters we only update the filters which actually changed
                let reducedUserInput = { ...userInput }
                Object.entries(userInput).forEach(([key, value]) => {
                    let column = columns[key]
                    if (key === "subgroup_title") {
                        // do nothing
                    } else if (column.type === "o" || column.type === "c") {
                        if (value.length === column.values.length) {
                            delete reducedUserInput[key]
                        }
                    } else if (column.type === "n") {
                        if (value.min === column.min && value.max === column.max) {
                            delete reducedUserInput[key]
                        }
                    }
                })

                setLoading(true)
                api.updateNodeFilterConfig(pipeId, nodeId, usernameFromPath, reducedUserInput)
                    .then((response) => {
                        reloadPipe(reloadPipeCounter + 1)
                        setLoading(false)
                    }).catch((error) => {
                        setLoading(false)
                        Logger.error("Could not update filter config: " + JSON.stringify(error))
                    })
            }
        }
    }, [userInput])

    const ensureUserInputComplete = (savedUserInput, filterConfig) => {
        let newUserInput = { ...savedUserInput }

        // Here we ensure we have a user input for all available keys, since we only store the diff for easier handling in the backend
        // if the input file changes we might even have invalid filters here which we first remove
        let requiredKeys = Object.keys(filterConfig)
        Object.keys(newUserInput).forEach((key) => {
            if (key === "subgroup_title") {
                setSubgroupTitle(newUserInput["subgroup_title"])
                return // keep in user input
            }
            if (!requiredKeys.includes(key)) {
                delete newUserInput[key] // might be from an older file input
            }
        })

        requiredKeys.forEach((key) => {
            if (!(key in newUserInput)) {
                let value = filterConfig[key]
                if (value.type === "n") {
                    newUserInput[key] = value
                } else {
                    newUserInput[key] = value.values.map((v) => (v[key]))
                }
            }
        })

        setUserInput(newUserInput)
    }

    const visibleColumns = () => {
        if (Object.keys(columns).length === 0) { return [] }
        // show columns with less entries first
        var columnEntries = Object.entries(columns)
        let categoricalEntries = columnEntries.filter(([key, value]) => value.type === "c")
        let numericEntries = columnEntries.filter(([key, value]) => value.type === "n")
        columnEntries = categoricalEntries.sort((a, b) => a[1].values.length - b[1].values.length).concat(numericEntries)

        // check if we should hide certain columns
        if (block && block.configuration && block.configuration.elements && block.configuration.elements.length === 1) {
            let visibleColumns = block.configuration.elements[0].selected_values
            columnEntries = columnEntries.filter(([key, value]) => visibleColumns.includes(key))
        }

        return columnEntries
    }

    const handleFilterChange = (colName, selectedRows) => {
        if (columns[colName].type === "n") {
            return false
        }
        let newUserInput = structuredClone(userInput)
        newUserInput[colName] = selectedRows
        setUserInput(newUserInput)
        setValueChanged(true)
    }

    const isChecked = (col, rowValue) => {
        return userInput[col]?.indexOf(rowValue) !== -1
    }

    const allChecked = (colName) => {
        if (columns[colName]?.type === "n" || columns[colName]?.type === "o") {
            // TODO:
            return false;
        } else {
            return userInput[colName]?.length === columns[colName]?.values?.length
        }
    }

    const isIndeterminate = (colName) => {
        return userInput[colName]?.length > 0 && !allChecked(colName)
    }

    const minMaxFilterChanged = (colName, min, max) => {
        let newUserInput = structuredClone(userInput)
        newUserInput[colName].min = min
        newUserInput[colName].max = max
        // TODO: check if values are numerical (eg. no "46.")
        setUserInput(newUserInput)
    }

    const subgroupNameChanged = (name) => {
        let newUserInput = structuredClone(userInput)
        newUserInput["subgroup_title"] = name
        setUserInput(newUserInput)
    }

    const resetAllFilters = () => {
        let newUserInput = structuredClone(userInput)
        Object.keys(newUserInput).forEach((key) => {
            let column = columns[key]
            if (newUserInput[key].type === "n" && column.type === "n") {
                newUserInput[key] = { min: column.min, max: column.max }
            } else if (key !== "subgroup_title") {
                newUserInput[key] = column.values?.map((v) => (v[key]))
            }
        })
        setUserInput(newUserInput)
    }

    const masonryColumns = () => {
        var containsCountColumn = true
        let categoricalColumns = Object.values(columns).filter((c) => (c.type === 'c'))
        if (categoricalColumns.length > 0 && categoricalColumns[0].values.length > 0) {
            containsCountColumn = Object.keys(categoricalColumns[0].values[0]).length > 1
        }

        var columnsConfig = { onecol: 1 }
        if (containsCountColumn) {
            columnsConfig.twocol = 2
            columnsConfig.threecol = 3
            columnsConfig.fourcol = 4
            columnsConfig.fivecol = 5
            columnsConfig.sixcol = 6
        } else {
            columnsConfig.twocol_small = 2
            columnsConfig.threecol_small = 3
            columnsConfig.fourcol_small = 4
            columnsConfig.fivecol_small = 5
            columnsConfig.sixcol_small = 6
        }
        return columnsConfig
    }

    return (
        <Stack direction={"column"} spacing={3}>
            {visibleColumns().length == 0 && !loading &&
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}><Typography variant="h5">Please specify visible columns.</Typography></div>}
            {blockType === "create_subgroup" &&
                <CreateSubgroupNameComponent
                    disabled={isForeignPipe()}
                    initialTitle={subgroupTitle}
                    onSaveButtonClick={subgroupNameChanged}
                />
            }
            <Masonry columns={masonryColumns()}>
                {visibleColumns().map(([columnName, column], index) => {
                    let filterIndex = Object.keys(userInput).indexOf(columnName)
                    if (column.type === "c") {
                        return (
                            <Box key={index} sx={{ mr: 2, mb: 2 }} >
                                <FilterListComponent
                                    title={columnName}
                                    items={column.values}
                                    filterIndex={filterIndex}
                                    isChecked={(rowValue) => (isChecked(columnName, rowValue[columnName]))}
                                    isForeignPipe={isForeignPipe}
                                    handleFilterChange={(selectedRows) => (handleFilterChange(columnName, selectedRows))}
                                    disabledRows={disabledRows}
                                    isLoading={loading}
                                />
                            </Box>
                        )
                    } else if (column.type === "n" && blockType !== "create_subgroup") {
                        return (
                            <Box sx={{ mr: 2, mb: 2, maxWidth: 230 }} key={index}>
                                <FilterMinMaxComponent
                                    columnName={columnName}
                                    column={column}
                                    userInput={userInput[columnName]}
                                    filterChanged={(minValue, maxValue) => {
                                        minMaxFilterChanged(columnName, minValue, maxValue)
                                    }}
                                    isForeignPipe={isForeignPipe}
                                />
                            </Box>
                        )
                    }
                })}
            </Masonry>
            {showTable && (
                <DataLoadingTableComponent
                    pipeId={pipeId}
                    nodeId={nodeId}
                    username={usernameFromPath}
                    reloadPipeCounter={reloadTableCounter} // we have two reload counters because we need to reload the table, but not the pipe/filters when the values change
                    reloadPipe={reloadPipe}
                />
            )}
            {document.getElementById(`block-${nodeId}-details-button-portal`) && blockType !== "create_subgroup" && createPortal(
                <Stack direction={"row"} spacing={1}>
                    <Tooltip title="Reset all filters">
                        <IconButton
                            color="inherit"
                            onClick={(event) => {
                                event.stopPropagation()
                                resetAllFilters()
                            }}
                        >
                            <RestartAlt />
                        </IconButton>
                    </Tooltip>
                    {valueChanged && <Tooltip title="Apply filters & Reload page">
                        <Button variant="contained" color="primary" onClick={(event) => {
                            event.stopPropagation()
                            onSelectionChanged()
                        }} startIcon={<FilterList />}>
                            Apply
                        </Button>
                    </Tooltip>}
                </Stack>,
                document.getElementById(`block-${nodeId}-details-button-portal`)
            )}
        </Stack>
    )
}

export default FilterPageComponent;