import { FormControl, Select, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { ConfigurePlotElementHeader } from './PlotComponents/ConfigurePlotSidebarComponent';
import AdminModeOptionsList from './AdminModeOptionsList';
import { ExpandMore } from "@mui/icons-material";

const SingleSelectConfigList = ({ 
    config, 
    onSelectOption, 
    isForeignPipe, 
    onChangeIsSynced, 
    onChangeSortDescending, 
    displayNone = true,
    isAdminMode = false,
    onVisibilityChange
}) => {

    const isDisabled = () => {
        if (isForeignPipe) {
            return isForeignPipe()
        } else {
            return false
        }
    }

    const renderOptions = () => {
        if (isAdminMode) {
            
            return (
                <Accordion defaultExpanded>
                    <AccordionSummary sx={{ mb: -1 }} expandIcon={<ExpandMore />}>
                        <Typography><strong>Visible Columns</strong></Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ maxHeight: '50vh', overflow: 'auto' }}>
                        <AdminModeOptionsList 
                            config={config}
                            onVisibilityChange={onVisibilityChange}
                            displayNone={displayNone}
                        />
                    </AccordionDetails>
                </Accordion>
            );
        }

        return (
            <Select
                native
                displayEmpty
                value={config.selected_values[0]}
                onChange={(event) => {
                    let value = event.target.value
                    onSelectOption(value)
                }}
                disabled={isDisabled()}
            >
                {displayNone && <option value="" key={-1}>None</option>}
                {config.options && config.options
                    .filter(option => !config.hidden_options?.includes(option))
                    .map((option, index) => (
                        <option value={option} key={index}>{option}</option>
                    ))}
            </Select>
        );
    };

    return (
        <>
            <ConfigurePlotElementHeader 
                config={config} 
                onChangeIsSynced={onChangeIsSynced} 
                onChangeSortDescending={onChangeSortDescending} 
            />
            <FormControl style={{ boxSizing: 'border-box' }}>
                {renderOptions()}
            </FormControl>
        </>
    );
};

export default SingleSelectConfigList;