import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { ApiContext } from './ApiContext'
import { decodeBase64 } from './decodeBase64'

// Basic Hooks
export function usePing() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['ping'],
    queryFn: async () => {
      const response = await api.ping()
      return response.data
    }
  })
}

// Auth Related Hooks
export function useSignup() {
  const { api } = useContext(ApiContext)
  return useMutation({
    mutationFn: async (params) => {
      const response = await api.signup(params)
      return response.data
    }
  })
}

export function useLogin() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationKey: ['login'],
    mutationFn: async (params) => {
      const response = await api.login(params)
      return response
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userProfile'] })
      queryClient.invalidateQueries({ queryKey: ['pipes'] })
    }
  })
}

export function useResetPassword() {
  const { api } = useContext(ApiContext)
  return useMutation({
    mutationFn: async ({ user, newPassword }) => {
      const response = await api.resetPassword(user, newPassword)
      return response.data
    }
  })
}

// Organization Hooks
export function useCreateOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: async (orgName) => {
      const response = await api.createOrganization(orgName)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
    }
  })
}

export function useBlocks() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['blocks'],
    queryFn: async () => {
      const response = await api.getBlocks()
      return response.data
    }
  })
}

export function useOrganizations() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['organizations'],
    queryFn: async () => {
      const response = await api.getOrganizations()
      return response.data
    }
  })
}

export function useUpdateBlockOrder() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, blocks, username }) => {
      const response = await api.updateBlockOrder(pipeId, blocks, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['pipe', variables.pipeId] 
      })
    }
  })
}

// Template Hooks
export function useTemplates() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['templates'],
    queryFn: async () => {
      const response = await api.getTemplates()
      return response.data
    },
    staleTime: 1000 * 60 * 5 // 5 minutes
  })
}

// TODO: This may not be needed, see usePipe
export function useTemplate(templateId) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['template', templateId],
    queryFn: async () => {
      const response = await api.getTemplate(templateId)
      return response.data
    }
  })
}

export function useCreateTemplate() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, username }) => {
      const response = await api.createTemplate(pipeId, username)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] })
    }
  })
}

export function useUpdateTemplateTag() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ templateId, tag }) => {
      const response = await api.updateTemplateTag(templateId, tag)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['template', variables.templateId] 
      })
    }
  })
}

export function useDeleteTemplate() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (templateId) => {
      const response = await api.deleteTemplate(templateId)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] })
    }
  })
}

// User Related Hooks
export function useUserProfile() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const response = await api.getUserProfile()
      return response.data
    },
    retry: 1,
    retryDelay: 1000
  })
}

// Pipe Related Hooks
export function usePipes(username) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['pipes', username],
    queryFn: async () => {
      const response = await api.getUserPipes(username)
      return response.data
    },
    enabled: !!username
  })
}

export function usePipe(pipeId, username, isTemplate = false, enabled = true) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['pipe', pipeId, username],
    queryFn: async () => {
      const response = isTemplate ? 
        await api.getTemplate(pipeId) :
        await api.getUserPipe(pipeId, username)
      return response.data
    },
    enabled: !!pipeId && !!username && enabled
  })
}

export function useBlock(pipeId, blockId, username) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['block', pipeId, blockId, username],
    queryFn: async () => {
      const response = await api.getBlock(pipeId, blockId, username)
      return response.data
    }
  })
}

export function useBranchMap(pipeId, username, isTemplate = false) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['branchMap', pipeId, username],
    queryFn: async () => {
      const response = await api.getBranchMap(pipeId, username)
      return response.data
    },
    enabled: !isTemplate && !!username
  })
}

export function useCreatePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ name, templateId }) => {
      const response = await api.createNewPipe(name, templateId)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pipes'] })
    }
  })
}

export function useClonePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, owner }) => {
      const response = await api.clonePipe(pipeId, owner)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pipes'] })
    }
  })
}

export function useDeletePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ username, pipeId }) => {
      const response = await api.deletePipe(username, pipeId)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipes', variables.username] })
    }
  })
}

// File Related Hooks
export function useUserFiles(username) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['files', username],
    queryFn: async () => {
      const response = await api.getUserFiles(username)
      return response.data
    }
  })
}

export function useFile(filename) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['file', filename],
    queryFn: async () => {
      const response = await api.getFile(filename)
      return response.data
    }
  })
}

export function useUpdateFileDTypeMapping() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ filename, mapping, sources }) => {
      const response = await api.updateFileDTypeMapping(filename, mapping, sources)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['file', variables.filename] })
    }
  })
}

export function useUpdateFileCostCenters() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ filename, costCenters }) => {
      const response = await api.updateFileCostCenters(filename, costCenters)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['file', variables.filename] })
    }
  })
}

export function useUpdateFileCrop() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ filename, crop }) => {
      const response = await api.updateFileCrop(filename, crop)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['file', variables.filename] })
    }
  })
}

export function useUpdateFileOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ filename, organization }) => {
      const response = await api.updateFileOrganization(filename, organization)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['file', variables.filename] })
    }
  })
}

// Filter Related Hooks
export function useFilterConfig(pipeId, blockId, username) {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['filterConfig', pipeId, blockId, username],
    queryFn: async () => {
      const response = await api.getFilterConfig(pipeId, blockId, username)
      return response.data
    }
  })
}

// Pipe Update Hooks
export function useUpdatePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: async ({ pipeId, params, username }) => {
      const response = await api.updateUserPipe(pipeId, params, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useAddNodeToPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, node, username }) => {
      const response = await api.addNodeToPipe(pipeId, node, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useUpdateNodeInPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, value }) => {
      const response = await api.updateNodeInPipe(pipeId, nodeId, username, value)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['pipe', variables.pipeId],
        queryKey: ['block', variables.pipeId, variables.nodeId]
      })
    }
  })
}

export function useUpdateNodeFilterConfig() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, value }) => {
      const response = await api.updateNodeFilterConfig(pipeId, nodeId, username, value)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['filterConfig', variables.pipeId, variables.nodeId]
      })
    }
  })
}

export function useUpdateNodeConfiguration() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, configuration }) => {
      const response = await api.updateNodeConfiguration(pipeId, nodeId, username, configuration)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['block', variables.pipeId, variables.nodeId]
      })
    }
  })
}

export function useUpdateNodeInDashboard() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, viewInDashboard }) => {
      const response = await api.updateNodeInDashboard(pipeId, nodeId, username, viewInDashboard)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useUpdateNodeSkipFlag() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, skip }) => {
      const response = await api.updateNodeSkipFlag(pipeId, nodeId, username, skip)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useUpdateNodeDetails() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username, title, description }) => {
      const response = await api.updateNodeDetails(pipeId, nodeId, username, title, description)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ 
        queryKey: ['block', variables.pipeId, variables.nodeId]
      })
    }
  })
}

export function useMoveNodeInPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, position, username }) => {
      const response = await api.moveNodeInPipe(pipeId, nodeId, position, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useDeleteNodeFromPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, nodeId, username }) => {
      const response = await api.deleteNodeFromPipe(pipeId, nodeId, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useAddEdgeToPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, edge, username }) => {
      const response = await api.addEdgeToPipe(pipeId, edge, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

export function useDeleteEdgeFromPipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, edgeId, username }) => {
      const response = await api.deleteEdgeFromPipe(pipeId, edgeId, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

// Pipe Snapshot Hooks
export function useSavePipeSnapshot() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, title, description, tags }) => {
      const response = await api.savePipeSnapshot(pipeId, title, description, tags)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

// File Upload/Delete Hooks
export function useUploadFiles() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ files, overwrite, onUploadProgress }) => {
      const response = await api.uploadFiles(files, overwrite, onUploadProgress)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['files'] })
    }
  })
}

export function useDeleteFile() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (filename) => {
      const response = await api.deleteFile(filename)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['files'] })
    }
  })
}

// Pipe Execution Hooks
export function useRunPipe() {
  const { api } = useContext(ApiContext)
  
  return useMutation({
    mutationFn: async ({ 
      id, 
      untilNodeId, 
      rowLimit, 
      username, 
      start, 
      size, 
      filters, 
      globalFilter, 
      sorting 
    }) => {
      const response = await api.runPipe(
        id, 
        untilNodeId, 
        rowLimit, 
        username, 
        start, 
        size, 
        filters, 
        globalFilter, 
        sorting
      )
      return response.data
    }
  })
}

// Plot Related Hooks
export function useSpec() {
  const { api } = useContext(ApiContext)
  
  return useMutation({
    mutationFn: async ({ 
      pipeId, 
      blockId, 
      username, 
      height, 
      filterList, 
      signal, 
      presentationMode 
    }) => {
      const response = await api.getSpec({
        pipe_id: pipeId,
        block_id: blockId,
        username,
        height,
        filter_list: filterList,
        signal,
        presentation_mode: presentationMode
      })
      return response.data
    }
  })
}

export function usePlotPreview(params) {
  const { api } = useContext(ApiContext);
  
  return useQuery({
    queryKey: ['plotPreview', params.pipeId, params.blockId, params.username, params.sparsify, params.height, params.width, params.presentationMode],
    queryFn: async () => {
      const response = await api.getPlotPreview({
        pipe_id: params.pipeId,
        block_id: params.blockId,
        username: params.username,
        sparsify: params.sparsify,
        height: params.height,
        width: params.width,
        signal: params.signal,
        presentationMode: params.presentationMode,
        includeDescription: params.includeDescription,
        format: params.format
      });
      let description = decodeBase64(response.headers?.get("x-plot-description") || "");
      return {
        blob: response.data,
        description: description
      };
    },
    gcTime: 1000 * 60 * 5, // Keep cache for 5 minutes
    staleTime: 1000 * 60 * 2, // Consider data stale after 2 minutes
    enabled: !!(params.pipeId && params.blockId && params.username), // Only run when we have required params
  });
}

export function useUploadScreenshot() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, screenshotFile, username }) => {
      const response = await api.uploadScreenshot(pipeId, screenshotFile, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

// Admin Related Hooks
export function useUsers() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await api.getUsers()
      return response.data
    }
  })
}

export function useDeleteUser() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (user) => {
      const response = await api.deleteUser(user)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export function useUpdateUserRole() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ user, role }) => {
      const response = await api.updateUserRole(user, role)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export function useUpdateUserOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ user, organization }) => {
      const response = await api.updateUserOrganization(user, organization)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export function useUpdateUserCostCenters() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ user, costCenters }) => {
      const response = await api.updateUserCostCenters(user, costCenters)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export function useUpdateTemplateCostCenters() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ templateId, costCenters }) => {
      const response = await api.updateTemplateCostCenters(templateId, costCenters)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['template', variables.templateId] })
    }
  })
}

export function useUpdateTemplateOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ templateId, organization }) => {
      const response = await api.updateTemplateOrganization(templateId, organization)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['template', variables.templateId] })
    }
  })
}

export function useUpdateUserCrops() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ user, crops }) => {
      const response = await api.updateUserCrops(user, crops)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

// Pipe Operations
export function useRenamePipe() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pipeId, name, username }) => {
      const response = await api.renamePipe(pipeId, name, username)
      return response.data
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pipe', variables.pipeId] })
    }
  })
}

// User Operations
export function useUpdateUserUndefined() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['userUndefined'],
    queryFn: () => api.currentUserUndefined
  })
}

export function useCurrentError() {
  const { api } = useContext(ApiContext)
  return useQuery({
    queryKey: ['currentError'],
    queryFn: () => api.currentError
  })
}

// Auth Operations
export function useLogout() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      await api.logout()
    },
    onSuccess: () => {
      queryClient.invalidateQueries() // Invalidate all queries
    }
  })
}

// Organization Operations
export function useUpdateOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ orgId, params }) => {
      const response = await api.updateOrganization(orgId, params)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
    }
  })
}

export function useDeleteOrganization() {
  const { api } = useContext(ApiContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (orgId) => {
      const response = await api.deleteOrganization(orgId)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
    }
  })
}
