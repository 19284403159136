import { ArrowDownward, ArrowUpward, Link, LinkOff, AdminPanelSettings } from '@mui/icons-material';
import { Button, Checkbox, Stack, Tooltip, Typography, IconButton } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DefaultPaper from 'components/DefaultPaper';
import MultiSelectConfigList from 'components/MultiSelectConfigList';
import SingleSelectConfigList from 'components/SingleSelectConfigList';
import { useEffect, useState } from 'react';
import { Switch } from "@mui/material";
import useFeatureFlags from 'helper/useFeatureFlags';

export const ConfigurePlotElementHeader = ({ config, onChangeIsSynced, onChangeSortDescending }) => {
    return (
        <Stack direction="row">
            <Typography variant="h6">{config.name}</Typography>
            {!(isNaN(config.is_synced) || config.is_synced === null) && (
                <Tooltip title={config.is_synced ? "Unsync axes" : "Sync axes"}>
                    <Checkbox
                        sx={{ mt: -0.5 }}
                        checked={config.is_synced}
                        icon={<LinkOff />}
                        checkedIcon={<Link />}
                        onChange={(event) => {
                            let newIsSynced = event.target.checked
                            onChangeIsSynced(newIsSynced)
                        }}
                    />
                </Tooltip>
            )}
            {!(isNaN(config.sort_ascending) || config.sort_ascending === null) && (
                <Tooltip title={config.sort_ascending == true ? "Sort descending" : "Sort ascending"}>
                    <Checkbox
                        sx={{ mt: -0.5 }}
                        checked={config.sort_ascending}
                        icon={<ArrowDownward />}
                        checkedIcon={<ArrowUpward />}
                        onChange={(event) => {
                            let newSortDescending = event.target.checked
                            onChangeSortDescending(newSortDescending)
                        }}
                    />
                </Tooltip>
            )}
        </Stack>
    )
}

const ConfigurePlotSidebarComponent = ({
    onSaveConfig,
    isForeignPipe,
    initialConfig = {},
    isShowTableBlock = false,
    isAdminMode = false,
    onAdminModeToggle
}) => {
    const { currentUserIsAdmin } = useFeatureFlags();
    const [state, setState] = useState({ elements: [] });

    useEffect(() => {
        setState(initialConfig);
    }, [initialConfig]);

    const onSelectSingleSelectOption = (option, index) => {
        let newState = { ...state };
        newState.elements[index].selected_values = [option];
        setState(newState);
    };

    const onSelectMultiSelectOption = (option, index) => {
        let newState = { ...state };
        let selectedValues = newState.elements[index].selected_values || [];

        if (isShowTableBlock) {
            if (selectedValues.includes(option)) {
                selectedValues = selectedValues.filter((value) => value !== option);
            } else {
                selectedValues.push(option);
            }
            newState.elements[index].selected_values = selectedValues;
        } else {
            newState.elements[index].selected_values = option;
        }
        setState(newState);
    };

    const onReorderOptions = (index, newOptions, selectedValues) => {
        let newState = { ...state };
        // Update the options with the new order
        newState.elements[index].options = newOptions;
        // Update the selected_values based on the new order of options
        const newSelectedValues = newOptions.filter(option => selectedValues.includes(option));
        newState.elements[index].selected_values = newSelectedValues;
        setState(newState);  // Update the state with both reordered options and selected_values
    };

    const onChangeIsSynced = (index, value) => {
        let newState = { ...state };
        newState.elements[index].is_synced = value;
        setState(newState);
    };

    const onChangeSortDescending = (index, value) => {
        let newState = { ...state };
        newState.elements[index].sort_ascending = value;
        setState(newState);
    };

    const handleVisibilityChange = (elementIndex, option) => {
        let newState = { ...state };
        const element = newState.elements[elementIndex];
        if (!element.hidden_options) {
            element.hidden_options = [];
        }

        if (element.hidden_options.includes(option)) {
            element.hidden_options = element.hidden_options.filter(o => o !== option);
        } else {
            element.hidden_options.push(option);
        }

        setState(newState);
    };

    const onToggleSwitchChange = (index, checked) => {
        let newState = { ...state };
        newState.elements[index].toggle = checked;
        setState(newState);
    };


    return (
        <DefaultPaper
            additionalSx={{ height: '80vh', overflow: "auto", pl: 0, pt: 0 }}
        >
            <List>
                {currentUserIsAdmin && (
                    <Stack direction="row" justifyContent="space-between" sx={{ p: 1 }}>
                        <div>
                            <Tooltip title={isAdminMode ? "Switch to Normal Mode" : "Switch to Admin Mode"}>
                                <IconButton onClick={onAdminModeToggle}>
                                    <AdminPanelSettings color={isAdminMode ? "primary" : "inherit"} />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="caption">{isAdminMode ? "Switch to Normal Mode" : "Switch to Admin Mode"}</Typography>
                        </div>
                    </Stack>
                )}
                {state.elements.map((config, index) => (
                    <ListItem key={index}>
                        <Stack direction="column" sx={{ width: '100%' }}>
                            {config.type === "single_select" &&
                                <SingleSelectConfigList
                                    config={config}
                                    onSelectOption={(option) => onSelectSingleSelectOption(option, index)}
                                    isForeignPipe={isForeignPipe}
                                    onChangeIsSynced={(value) => (onChangeIsSynced(index, value))}
                                    onChangeSortDescending={(value) => (onChangeSortDescending(index, value))}
                                    onVisibilityChange={(option) => handleVisibilityChange(index, option)}
                                    isAdminMode={isAdminMode}
                                />}
                            {config.type === "multi_select" &&
                                <MultiSelectConfigList
                                    config={config}
                                    onSelectOption={(option) => onSelectMultiSelectOption(option, index)}
                                    isForeignPipe={isForeignPipe}
                                    onChangeIsSynced={(value) => (onChangeIsSynced(index, value))}
                                    onChangeSortDescending={(value) => (onChangeSortDescending(index, value))}
                                    onOptionsReorder={(newOptions, selectedValues) => onReorderOptions(index, newOptions, selectedValues)}
                                    isShowTableBlock={isShowTableBlock}
                                    onVisibilityChange={(option) => handleVisibilityChange(index, option)}
                                    isAdminMode={isAdminMode}
                                />}
                            {config.type === "toggle_switch" && (
                                <Stack direction="row" alignItems="center">
                                    <Typography variant="h6">{config.name}</Typography>
                                    <Switch
                                        checked={config.toggle}
                                        onChange={(event) => onToggleSwitchChange(index, event.target.checked)}
                                    />
                                </Stack>
                            )}
                        </Stack>
                    </ListItem>
                ))}
                <Button
                    variant="contained"
                    sx={{ ml: 2, mb: 2}}
                    onClick={() => onSaveConfig(state)}
                    disabled={isForeignPipe()}
                >
                    Save & Reload
                </Button>
            </List>
        </DefaultPaper>
    )
}

export default ConfigurePlotSidebarComponent