import { Box, Button, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import StaticDataTableComponent from 'components/table/StaticDataTableComponent';
import { useRef, useCallback } from 'react';
import useFeatureFlags from "helper/useFeatureFlags";

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1)
}

// massively inspired by https://mui.com/material-ui/react-transfer-list/
export const FilterListComponent = ({ title, items, filterIndex, isChecked, isForeignPipe, handleFilterChange, disabledRows, isLoading = false }) => {
    const { exludeImplicitDeselectedOptionsFromFilter } = useFeatureFlags()
    const checkedItems = intersection(items, items.filter((row) => (isChecked(row))))

    const visibleItems = exludeImplicitDeselectedOptionsFromFilter ? items.filter((row) => (!disabledRows[title]?.includes(row[title]))) : items;
    const initialSelectionState = checkedItems.reduce((accumulator, checkedItem) => {
        if (checkedItem[title] !== undefined) {
            accumulator[checkedItem[title]] = true;
        }
        return accumulator;
    }, {});

    const currentSelectionRef = useRef(initialSelectionState);
    const saveButtonRef = useRef(null);

    const onTableSelectionChange = useCallback((newSelection) => {
        currentSelectionRef.current = newSelection;
        const hasChanged = Object.keys(newSelection).some(key => 
            newSelection[key] !== initialSelectionState[key]
        ) || Object.keys(initialSelectionState).some(key => 
            !(key in newSelection) && initialSelectionState[key]
        );
        
        if (hasChanged && saveButtonRef.current) {
            saveButtonRef.current.style.display = 'block';
        } else if (!hasChanged && saveButtonRef.current) {
            saveButtonRef.current.style.display = 'none';
        }
    }, [initialSelectionState]);

    const onSave = () => {
        handleFilterChange(Object.keys(currentSelectionRef.current).filter(key => currentSelectionRef.current[key]));
        if (saveButtonRef.current) {
            saveButtonRef.current.style.display = 'none';
        }
    };

    return (
        <Card>
            <StaticDataTableComponent
                data={visibleItems}
                categoricalFilter={true}
                category={title}
                isForeignPipe={isForeignPipe}
                handleFilterChange={onTableSelectionChange}
                initialSelectionState={initialSelectionState}
                disabledRows={disabledRows[title]}
                isLoading={isLoading}
            />
            <Box ref={saveButtonRef} style={{display: 'none'}}>
                <Stack direction="row" sx={{margin: 2}}>
                    <Button
                        variant="contained"
                        onClick={onSave}
                        disabled={isForeignPipe()}
                    >
                        Save
                    </Button>
                    <Typography variant="body2" sx={{ml: "auto", mr: 3, alignContent: "center"}}>
                        {parseInt(filterIndex) + 1}
                    </Typography>
                </Stack>
            </Box>
        </Card>
    );
};

export default FilterListComponent;
