import LocalStorageService from 'helper/LocalStorageService';
import Route from 'helper/Route';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

// check if we should replace the endpoint at runtime
if (window.REACT_APP_API_ENDPOINT === "REPLACE_REACT_APP_API_ENDPOINT") {
    // we expect the real value to be set in the environment
    if (process.env.REACT_APP_API_ENDPOINT) {
        window.REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    } else if (process.env.REACT_APP_API_ENDPOINT_DEFAULT) {
        // otherwise we fall back to the compile time value
        window.REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_DEFAULT
    } else {
        console.log(`Could not set REACT_APP_API_ENDPOINT from ENV or default!`);
    }
}

if (window.REACT_APP_ENV === "REPLACE_REACT_APP_ENV") {
    // we expect the real value to be set in the environment
    if (process.env.REACT_APP_ENV) {
        window.REACT_APP_ENV = process.env.REACT_APP_ENV
    } else if (process.env.REACT_APP_ENV_DEFAULT) {
        window.REACT_APP_ENV = process.env.REACT_APP_ENV_DEFAULT
    } else {
        console.log(`Could not set REACT_APP_ENV_DEFAULT from ENV or default!`);
    }
}

// https://stackoverflow.com/a/10036029
if (typeof window !== 'undefined' &&
    window.location &&
    window.location.protocol !== "https:" &&
    !window.location.hostname.includes("matrix") &&
    window.REACT_APP_ENV !== "local-dev") {
    window.location.protocol = "https:";
}

if (window.location.hash.startsWith("#" + Route.AuthCallback)) {
    let url = new URL(window.location.href.replace("#/", ""));
    let token = url.searchParams.get("token");
    LocalStorageService().setToken(token);
    window.location.assign("/");
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);