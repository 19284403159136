import { Box, Grid, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import DefaultPaper from "components/DefaultPaper";
import BrushSelectionListPlotComponent from "components/PlotComponents/BrushSelectionListPlotComponent";
import ConfigurePlotSidebarComponent from "components/PlotComponents/ConfigurePlotSidebarComponent";
import LoadingSnackbar from "components/snackbars/LoadingSnackbar";
import { PlotBlocks, TableBlocks, TableBlocksWithTableOnDashboard } from "helper/Constants";
import Logger from "helper/Logger";
import { useIsForeignPipe } from "helper/UrlUtils";
import { useContext, useEffect, useState, useRef } from "react";
import { ApiContext } from "../../helper/ApiContext";
import DataLoadingTableComponent from "../table/DataLoadingTableComponent";
import BlockDetailsComponent from "./BlockDetailsComponent";
import FilterPageComponent from "./FilterPageComponent";
import ListFilesPageComponent from "./ListFilesPageComponent";
import ExportPageComponent from "./ExportPageComponent";
import PlotSwapPageComponent from "./PlotSwapPageComponent";
import useFeatureFlags from "helper/useFeatureFlags";

const BlockPageComponent = ({
    pipeId,
    nodeId,
    usernameFromPath,
    reload,
    expanded, 
    toggleExpansion,
    overrideShowStaticPlot,
    showTable = true,
    isDashboardItem = false,
    hideBlockDetails = false,
    onSelectionChanged = () => { }
}) => {

    const { api } = useContext(ApiContext);
    const [block, setBlock] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [reloadCounter, setReloadCounter] = useState(0)
    const blockType = (block) => (block?.blueprint?.type ?? "")
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const [isAdminMode, setIsAdminMode] = useState(false);
    const [swapCounter, setSwapCounter] = useState(0)
    const handleAdminModeToggle = () => { setIsAdminMode(!isAdminMode); };
    const headerRef = useRef(null)
    const [showStaticPlot, setShowStaticPlot] = useState(isDashboardItem)
    const { enableDashboardAccordions} = useFeatureFlags()

    const blockToShow = () => {
        if (blockType(block) === "plot_swap") {
            return block.parent_blocks[swapCounter % block.parent_blocks.length]
        } else {
            return block
        }
    }

    // we mirror the reload flag to an internal one, because some child components need it but want to modify it here too
    useEffect(() => {
        setReloadCounter(reloadCounter + 1)
    }, [reload])

    useEffect(() => {
        if (overrideShowStaticPlot !== undefined) {
            setShowStaticPlot(overrideShowStaticPlot)
        }
    }, [overrideShowStaticPlot])

    useEffect(() => {
        if (isLoading) return
        setIsLoading(true)
        api.getBlock(pipeId, nodeId, usernameFromPath).then((response) => {
            setIsLoading(false)
            setBlock(response.data)
        }).catch((error) => {
            Logger.error("BlockPageComponent: could not get block: " + JSON.stringify(error))
        })
    }, [reloadCounter])

    const onSaveBlockConfiguration = (config) => {
        api.updateNodeConfiguration(pipeId, blockToShow().flow_id, usernameFromPath, config).then(() => {
            Logger.info("Successfully updated node configuration")
            setReloadCounter(reloadCounter + 1)
        }).catch((error) => {
            Logger.error(error)
        })
    }

    const handleHeaderClick = (event) => {
        let clickedOnHeaderOrChild = Array.from(headerRef.current.querySelectorAll("*")).includes(event.target)
        if (clickedOnHeaderOrChild)
            toggleExpansion();
    }

    let header = () => {
        var blockToShow = block
        if (blockType(block) === "plot_swap") {
            blockToShow = block.parent_blocks[swapCounter % block.parent_blocks.length]
        }
        return (
            <BlockDetailsComponent  
                pipeId={pipeId}
                usernameFromPath={usernameFromPath}
                block={blockToShow}
                isForeignPipe={isForeignPipe}
                isOnDashboard={isDashboardItem}
                onSelectionChanged={onSelectionChanged}
                showStaticPlot={showStaticPlot}
                reload={reload}
                onShowInteractivePlotClicked={() => {
                    setShowStaticPlot(!showStaticPlot)
                }}
            />
        )
    }

    let content = () => {
        let type = blockType(block)
        if (type === "import") {
            return <ListFilesPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                usernameFromPath={usernameFromPath}
                showTable={showTable}
                onSelectionChanged={onSelectionChanged}
            />
        } else if (PlotBlocks.includes(type)) {
            return <BrushSelectionListPlotComponent
                pipeId={pipeId}
                nodeId={nodeId}
                reloadCounter={reloadCounter}
                onSelectionChanged={onSelectionChanged}
                showTable={showTable}
                usernameFromPath={usernameFromPath}
                loadStaticPlot={showStaticPlot}
                isDashboardItem={isDashboardItem}
            />
        } else if (TableBlocks.includes(type)) {
            if (!showTable && !TableBlocksWithTableOnDashboard.includes(blockType(block))) {
                return (<></>);
            } else {
                return <DataLoadingTableComponent 
                    pipeId={pipeId} 
                    nodeId={nodeId} 
                    username={usernameFromPath} 
                    reloadPipeCounter={reloadCounter} 
                    config={block.configuration} 
                    isShowTableBlock={type === "show_table"} 
                />
            }
        } else if (["filter_categories", "create_subgroup"].includes(type)) {
            return <FilterPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                blockType={type}
                usernameFromPath={usernameFromPath}
                reload={reloadCounter}
                showTable={showTable}
                onSelectionChanged={onSelectionChanged}
            />
        } else if (type === "export") {
            return <ExportPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                usernameFromPath={usernameFromPath}
                reload={reloadCounter}
                showTable={showTable}
            />
        } else if (type === "plot_swap") {
            return <PlotSwapPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                usernameFromPath={usernameFromPath}
                isDashboardItem={isDashboardItem}
                reload={reloadCounter}
                showTable={showTable}
                showStaticPlot={showStaticPlot}
                swapCounter={swapCounter}
                setSwapCounter={setSwapCounter}
                onSelectionChanged={onSelectionChanged}
                parentBlocks={block.parent_blocks}
            />
        } else {
            return (<div style={{ margin: 10 }}>Loading...</div>)
        }
    }

    let properlyWrappedContent = () => {
        if (hideBlockDetails) {
            return (
                <div style={{ padding: '10px' }}>
                    {content()}
                </div>
            )
        } else if (isDashboardItem && enableDashboardAccordions) {
            return (
                <div
                    style={{
                        marginBottom: '10px',
                        minWidth: '96vw'
                    }}
                >
                    <Accordion
                        key={block.id}
                        expanded={expanded}
                        onClick={handleHeaderClick}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            ref={headerRef}
                        >
                            {header()}
                        </AccordionSummary>
                        <AccordionDetails>
                            {content()}
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            let hideContent = isDashboardItem && (TableBlocks.includes(blockType(block)) && !TableBlocksWithTableOnDashboard.includes(blockType(block)))
            return (
                <div 
                    key={block.id} 
                    style={{ 
                        marginTop: isDashboardItem ? '0px' : '55px',
                        marginBottom: isDashboardItem ? '20px' : '0px',
                        border: '1px solid black',
                        backgroundColor: 'white',
                        minWidth: isDashboardItem ? '96vw' : '100%'
                    }}
                >
                    <div style={{ borderBottom: '1px solid black', padding: '10px' }}>
                        {header()}
                    </div>
                    {!hideContent && <div style={{ padding: '10px' }}>
                        {content()}
                    </div>}
                </div>
            )
        }
    }

    let showSidebar = block && blockType(block) !== "import" && !isDashboardItem && !hideBlockDetails && blockType(block) !== "export";
    if (showSidebar) {
        return (
            <Grid container spacing={2} sx={{ width: "97vw" }}>
                <LoadingSnackbar loading={isLoading} />
                <Grid item xs={10}>
                    {properlyWrappedContent()}
                </Grid>
                <Grid item xs={2} sx={{ maxHeight: '90px' }}>
                    {block && showTable &&
                        <ConfigurePlotSidebarComponent
                            onSaveConfig={(config) => onSaveBlockConfiguration(config)}
                            isForeignPipe={isForeignPipe}
                            initialConfig={blockToShow().configuration}
                            isShowTableBlock={blockType(blockToShow()) === "show_table"}
                            isAdminMode={isAdminMode}
                            onAdminModeToggle={handleAdminModeToggle}
                        />}
                </Grid>
            </Grid>
        )
    } else if (block) {
        return properlyWrappedContent()
    }
}

export default BlockPageComponent